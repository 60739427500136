import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

function RemoteTelemedicineTab({ label }: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [roomName, setRoomName] = useState('');
  const startMeeting = () => {
    const roomId = roomName || generateRoomId();
    if (e2ee) {
      router.push(`/rooms/${roomId}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${roomId}`);
    }
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}>Try Cliniverse for secure remote telemedicine.</p>
      <input
        id="roomName"
        type="text"
        placeholder="Room Name"
        value={roomName}
        onChange={(ev) => setRoomName(ev.target.value)}
      />
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start Meeting
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="/images/cliniverse-home.svg" alt="Cliniverse" style={{ width: '100px', height: 'auto' }}/>
          <h2>
            Remote meeting app built on Cliniverse,{' '}
            connect in any language, at any place.
          </h2>
        </div>
        <RemoteTelemedicineTab label="Remote Telemedicine" />
      </main>
      <footer data-lk-theme="default">
        Know more about Cliniverse at{' '}
        <a href="https://axonichealth.com" rel="noopener">
          axonichealth.com
        </a>
      </footer>
    </>
  );
};

export default Home;